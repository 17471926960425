import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Input } from "@ember/component";
import { concat, fn, get } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import EditNavigationMenuModal from "discourse/components/sidebar/edit-navigation-menu/modal";
import borderColor from "discourse/helpers/border-color";
import categoryBadge from "discourse/helpers/category-badge";
import dirSpan from "discourse/helpers/dir-span";
import { popupAjaxError } from "discourse/lib/ajax-error";
import Category from "discourse/models/category";
import { INPUT_DELAY } from "discourse-common/config/environment";
import i18n from "discourse-common/helpers/i18n";
import discourseDebounce from "discourse-common/lib/debounce";
import gt from "truth-helpers/helpers/gt";
import includes from "truth-helpers/helpers/includes";
import not from "truth-helpers/helpers/not";
export default class extends Component {
    @service
    currentUser;
    @service
    site;
    @service
    siteSettings;
    @tracked
    filter = "";
    @tracked
    filteredCategoryIds;
    @tracked
    onlySelected = false;
    @tracked
    onlyUnselected = false;
    @tracked
    selectedSidebarCategoryIds = [
        ...this.currentUser.sidebar_category_ids
    ];
    categoryGroupings = [];
    constructor(){
        super(...arguments);
        let categories1 = [
            ...this.site.categories
        ];
        if (!this.siteSettings.fixed_category_positions) {
            categories1.sort((a1, b1)=>a1.name.localeCompare(b1.name));
        }
        Category.sortCategories(categories1).reduce((categoryGrouping1, category1, index1, arr1)=>{
            if (category1.isUncategorizedCategory) {
                return categoryGrouping1;
            }
            categoryGrouping1.push(category1);
            const nextCategory1 = arr1[index1 + 1];
            if (!nextCategory1 || nextCategory1.level === 0) {
                this.categoryGroupings.push(categoryGrouping1);
                return [];
            }
            return categoryGrouping1;
        }, []);
    }
    get filteredCategoriesGroupings() {
        const filteredCategoryIds1 = new Set();
        const groupings1 = this.categoryGroupings.reduce((acc1, categoryGrouping1)=>{
            const filteredCategories1 = new Set();
            const addCategory1 = (category1)=>{
                if (this.#matchesFilter(category1)) {
                    if (category1.parentCategory?.parentCategory) {
                        filteredCategories1.add(category1.parentCategory.parentCategory);
                    }
                    if (category1.parentCategory) {
                        filteredCategories1.add(category1.parentCategory);
                    }
                    filteredCategoryIds1.add(category1.id);
                    filteredCategories1.add(category1);
                }
            };
            categoryGrouping1.forEach((category1)=>{
                if (this.onlySelected) {
                    if (this.selectedSidebarCategoryIds.includes(category1.id)) {
                        addCategory1(category1);
                    }
                } else if (this.onlyUnselected) {
                    if (!this.selectedSidebarCategoryIds.includes(category1.id)) {
                        addCategory1(category1);
                    }
                } else {
                    addCategory1(category1);
                }
            });
            if (filteredCategories1.size > 0) {
                acc1.push(Array.from(filteredCategories1));
            }
            return acc1;
        }, []);
        this.filteredCategoryIds = Array.from(filteredCategoryIds1);
        return groupings1;
    }
    #matchesFilter(category1) {
        return this.filter.length === 0 || category1.nameLower.includes(this.filter);
    }
    @action
    resetFilter() {
        this.onlySelected = false;
        this.onlyUnselected = false;
    }
    @action
    filterSelected() {
        this.onlySelected = true;
        this.onlyUnselected = false;
    }
    @action
    filterUnselected() {
        this.onlySelected = false;
        this.onlyUnselected = true;
    }
    @action
    onFilterInput(filter1) {
        discourseDebounce(this, this.#performFiltering, filter1, INPUT_DELAY);
    }
    #performFiltering(filter1) {
        this.filter = filter1.toLowerCase();
    }
    @action
    deselectAll() {
        this.selectedSidebarCategoryIds.clear();
    }
    @action
    toggleCategory(categoryId1) {
        if (this.selectedSidebarCategoryIds.includes(categoryId1)) {
            this.selectedSidebarCategoryIds.removeObject(categoryId1);
        } else {
            this.selectedSidebarCategoryIds.addObject(categoryId1);
        }
    }
    @action
    resetToDefaults() {
        this.selectedSidebarCategoryIds = this.siteSettings.default_navigation_menu_categories.split("|").map((id1)=>parseInt(id1, 10));
    }
    @action
    save() {
        this.saving = true;
        const initialSidebarCategoryIds1 = this.currentUser.sidebar_category_ids;
        this.currentUser.set("sidebar_category_ids", this.selectedSidebarCategoryIds);
        this.currentUser.save([
            "sidebar_category_ids"
        ]).then(()=>{
            this.args.closeModal();
        }).catch((error1)=>{
            this.currentUser.set("sidebar_category_ids", initialSidebarCategoryIds1);
            popupAjaxError(error1);
        }).finally(()=>{
            this.saving = false;
        });
    }
    static{
        template(`
    <EditNavigationMenuModal
      @title="sidebar.categories_form_modal.title"
      @disableSaveButton={{this.saving}}
      @save={{this.save}}
      @showResetDefaultsButton={{gt
        this.siteSettings.default_navigation_menu_categories.length
        0
      }}
      @resetToDefaults={{this.resetToDefaults}}
      @deselectAll={{this.deselectAll}}
      @deselectAllText={{i18n "sidebar.categories_form_modal.subtitle.text"}}
      @inputFilterPlaceholder={{i18n
        "sidebar.categories_form_modal.filter_placeholder"
      }}
      @onFilterInput={{this.onFilterInput}}
      @resetFilter={{this.resetFilter}}
      @filterSelected={{this.filterSelected}}
      @filterUnselected={{this.filterUnselected}}
      @closeModal={{@closeModal}}
      class="sidebar__edit-navigation-menu__categories-modal"
    >
      <form class="sidebar-categories-form">
        {{#if (gt this.filteredCategoriesGroupings.length 0)}}
          {{#each this.filteredCategoriesGroupings as |categories|}}
            <div
              class="sidebar-categories-form__row"
              style={{borderColor (get categories "0.color") "left"}}
            >

              {{#each categories as |category|}}
                <div
                  class="sidebar-categories-form__category-row"
                  data-category-id={{category.id}}
                  data-category-level={{category.level}}
                >
                  <label
                    class="sidebar-categories-form__category-label"
                    for={{concat
                      "sidebar-categories-form__input--"
                      category.id
                    }}
                  >
                    <div class="sidebar-categories-form__category-wrapper">
                      <div class="sidebar-categories-form__category-badge">
                        {{categoryBadge category}}
                      </div>
                      {{#unless category.parentCategory}}
                        <div
                          class="sidebar-categories-form__category-description"
                        >
                          {{dirSpan
                            category.description_excerpt
                            htmlSafe="true"
                          }}
                        </div>
                      {{/unless}}
                    </div>

                    <Input
                      id={{concat
                        "sidebar-categories-form__input--"
                        category.id
                      }}
                      class="sidebar-categories-form__input"
                      @type="checkbox"
                      @checked={{includes
                        this.selectedSidebarCategoryIds
                        category.id
                      }}
                      disabled={{not
                        (includes this.filteredCategoryIds category.id)
                      }}
                      {{on "click" (fn this.toggleCategory category.id)}}
                    />
                  </label>
                </div>
              {{/each}}
            </div>
          {{/each}}
        {{else}}
          <div class="sidebar-categories-form__no-categories">
            {{i18n "sidebar.categories_form_modal.no_categories"}}
          </div>
        {{/if}}
      </form>
    </EditNavigationMenuModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
